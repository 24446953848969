import { defineStore } from 'pinia';
import { getCityByIpAddress, getLocation } from '@/api/accuweather';

export const locationStore = defineStore('location', {
	state: () => ({
		currentLocation: {},
	}),
	getters: {
		radarSrc(state) {
			return (width, height) => {
				if (!!state?.currentLocation?.GeoPosition) {
					return (
						`https://api.accuweather.com/maps/v1/radar/static/globalSIR/tile?apikey=de13920f574d420984d3080b1fa6132b&zoom=5` +
						`&lon=${state.currentLocation.GeoPosition.Longitude}` +
						`&lat=${state.currentLocation.GeoPosition.Latitude}` +
						`&imgwidth=${width}` +
						`&imgheight=${height}` +
						`&language=${navigator.language}`
					);
				} else {
					return '';
				}
			};
		},
		adminArea() {
			const area = this.currentLocation?.AdministrativeArea;
			return area?.CountryID === 'US' && navigator.language.includes('en')
				? area?.ID
				: area?.LocalizedName;
		},
		locationTitle() {
			if (!!this.currentLocation) {
				return `${
					this.currentLocation?.LocalizedName
						? this.currentLocation?.LocalizedName
						: this.currentLocation?.EnglishName
				}, ${this.adminArea}`;
			} else {
				return null;
			}
		},
	},
	actions: {
		async fetchCurrentlocation(locationOverride) {
			if (!!locationOverride) {
				return getLocation(window.aw_connect_language, locationOverride).then(
					(locationData) => {
						if (locationData) window.accuweather.locationData = locationData;
						this.currentLocation = locationData;
					}
				);
			}

			return getCityByIpAddress(window.aw_connect_language).then(
				(locationData) => {
					if (locationData) window.accuweather.locationData = locationData;
					this.currentLocation = locationData;
				}
			);
		},
	},
});
