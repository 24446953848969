import { realFeelCategoriesArray } from './realFeelCategories';
export function getRealFeelCategoryValue(currentConditionsData) {
	let categoryElement = null;
	realFeelCategoriesArray.forEach((realFeelCategoryElement) => {
		if (
			currentConditionsData.RealFeelTemperature?.Imperial.Value >=
				realFeelCategoryElement.Values.Imperial.Low &&
			currentConditionsData.RealFeelTemperature?.Imperial.Value <=
				realFeelCategoryElement.Values.Imperial.High
		) {
			categoryElement = realFeelCategoryElement;
		}
	});
	return categoryElement;
}
