import { IndexType } from '@accuweather/accunodesdk';

const goodLifestyleColors = [
	'#dc250e',
	'#ffd701',
	'#ffd701',
	'#93c701',
	'#93c701',
];
const badLifestyleColors = [
	'#93c701',
	'#ffd701',
	'#f05514',
	'#dc250e',
	'#7a1570',
];

const goodLifestylePhrases = ['Poor', 'Fair', 'Good', 'Great', 'Ideal'];
const badLifestylePhrases = ['Low', 'Moderate', 'High', 'Very High', 'Extreme'];

const groupIndexTypes = {
	Health: [
		IndexType.ArthritisDay,
		IndexType.Sinus,
		IndexType.CommonColdForecast,
		IndexType.Flu,
		IndexType.Migraine,
		IndexType.Asthma,
	],
	Allergy: [
		IndexType.GrassPollen,
		IndexType.TreePollen,
		IndexType.RagweedPollen,
		IndexType.Dust,
		IndexType.MoldPollen,
	],
	OutdoorActivity: [
		IndexType.FishingForecast,
		IndexType.Running,
		IndexType.Golf,
		IndexType.Biking,
		IndexType.BeachAndPool,
		IndexType.Stargazing,
		IndexType.Hiking,
	],
	HomeAndGarden: [IndexType.LawnMowing, IndexType.Composting, IndexType.BBQ],
	Travel: [IndexType.Driving, IndexType.Flying],
	Pest: [IndexType.Mosquito, IndexType.IndoorPest, IndexType.OutdoorPest],
};

export const ascendingIndexTypes = [
	IndexType.FishingForecast,
	IndexType.Running,
	IndexType.Golf,
	IndexType.Biking,
	IndexType.BeachAndPool,
	IndexType.Stargazing,
	IndexType.Hiking,
	IndexType.FlightDelay,
	IndexType.Flying,
	IndexType.Driving,
	IndexType.LawnMowing,
	IndexType.Composting,
	IndexType.BBQ,
];
export const lifestyleIndexInfo = {
	[IndexType.FlightDelay]: { name: 'Air Travel', icon: 'air-travel' },
	[IndexType.Flying]: { name: 'Air Travel', icon: 'air-travel' },
	[IndexType.Allergy]: { name: 'Allergies', icon: 'allergies' },
	[IndexType.ArthritisDay]: { name: 'Arthritis', icon: 'arthritis' },
	[IndexType.Asthma]: { name: 'Asthma', icon: 'asthma' },
	[IndexType.Stargazing]: { name: 'Stargazing', icon: 'stargazing' },
	[IndexType.Biking]: { name: 'Biking & Cycling', icon: 'biking' },
	[IndexType.CommonColdForecast]: { name: 'Common Cold', icon: 'common-cold' },
	[IndexType.Flu]: { name: 'Flu', icon: 'cold-flu' },
	[IndexType.Construction]: { name: 'DIY', icon: 'diy' },
	[IndexType.Driving]: { name: 'Driving', icon: 'driving' },
	[IndexType.Dust]: { name: 'Dust & Dander', icon: 'dust-dander' },
	[IndexType.Concert]: { name: 'Events', icon: 'events' },
	[IndexType.FishingForecast]: { name: 'Fishing', icon: 'fishing' },
	[IndexType.Golf]: { name: 'Golf', icon: 'golf' },
	[IndexType.GrassPollen]: { name: 'Grass Pollen', icon: 'grass-pollen' },
	[IndexType.Frizz]: { name: 'Frizz', icon: 'hair-day' },
	[IndexType.Hiking]: { name: 'Hiking', icon: 'hiking' },
	[IndexType.HomeEnergy]: { name: 'Home Energy', icon: 'home-energy' },
	[IndexType.Hunting]: { name: 'Hunting', icon: 'hunting' },
	[IndexType.Composting]: { name: 'Composting', icon: 'lawn-garden' },
	[IndexType.LawnMowing]: { name: 'Lawn Mowing', icon: 'lawn-mowing' },
	[IndexType.Migraine]: { name: 'Migraine', icon: 'migraine' },
	[IndexType.MoldPollen]: { name: 'Mold', icon: 'mold' },
	[IndexType.Mosquito]: { name: 'Mosquitos', icon: 'mosquito-activity' },
	[IndexType.IndoorPest]: { name: 'Indoor Pests', icon: 'indoor-pests' },
	[IndexType.OutdoorPest]: { name: 'Outdoor Pests', icon: 'outdoor-pests' },
	[IndexType.BBQ]: {
		name: 'Outdoor Entertaining',
		icon: 'outdoor-entertaining',
	},
	[IndexType.RagweedPollen]: { name: 'Ragweed Pollen', icon: 'ragweed-pollen' },
	[IndexType.Running]: { name: 'Running', icon: 'running' },
	[IndexType.Sailing]: { name: 'Sailing', icon: 'sailing' },
	[IndexType.SchoolBusStop]: { name: 'School Day', icon: 'school-day' },
	[IndexType.Sinus]: { name: 'Sinus', icon: 'sinus' },
	[IndexType.Skiing]: { name: 'Skiing', icon: 'ski' },
	[IndexType.SnowDays]: { name: 'Snow Day', icon: 'snow-day' },
	[IndexType.BeachAndPool]: { name: 'Beach & Pool', icon: 'sun-sand' },
	[IndexType.TreePollen]: { name: 'Tree Pollen', icon: 'tree-pollen' },
};

export function getColor(lifeStyleIndex) {
	const colors = ascendingIndexTypes.includes(lifeStyleIndex.ID)
		? goodLifestyleColors
		: badLifestyleColors;

	return colors[lifeStyleIndex.CategoryValue - 1];
}

export function getPhrase(lifeStyleIndex) {
	const phrases = ascendingIndexTypes.includes(lifeStyleIndex.ID)
		? goodLifestylePhrases
		: badLifestylePhrases;

	return phrases[lifeStyleIndex.CategoryValue - 1];
}

export function getLink(lifeStyleIndex, baseLink, baseLinkSlug) {
	let link = lifeStyleIndex.Link,
		slug,
		param;
	if (link === null) {
		switch (lifeStyleIndex.ID) {
			case IndexType.IndoorPest:
				slug = 'pest-weather';
				param = 'indoor-pests';
				break;
			case IndexType.OutdoorPest:
				slug = 'pest-weather';
				param = 'outdoor-pests';
				break;
			default:
				slug = lifeStyleIndex.Name.toLowerCase().replace(' ', '-');
				break;
		}
		link = baseLink.replace(baseLinkSlug, slug);
	}
	//for indextypes that need a 'name' parameter
	switch (lifeStyleIndex.ID) {
		case IndexType.GrassPollen:
			param = 'grass-pollen';
			break;
		case IndexType.MoldPollen:
			param = 'mold';
			break;
		case IndexType.RagweedPollen:
			param = 'ragweed-pollen';
			break;
		case IndexType.TreePollen:
			param = 'tree-pollen';
			break;
		case IndexType.Dust:
			param = 'dust-dander';
			break;
		case IndexType.CommonColdForecast:
			param = 'common-cold';
			break;
		case IndexType.Flu:
			param = 'flu';
			break;
		case IndexType.LawnMowing:
			param = 'lawn-mowing';
			break;
		case IndexType.Composting:
			param = 'composting';
			break;
	}
	if (!!param) {
		link = new URL(link);
		link.searchParams.set('name', param);
		link = link.toString();
	}
	return link ?? '';
}

export function getActiveIndices(indexData) {
	let activeIndices = [];
	for (let group in groupIndexTypes) {
		let maxGroupValue = 0;
		let maxIndexType = {};
		let compareValues = (a, b) => {
			return a > b;
		};

		if (group === 'Travel') {
			maxGroupValue = 6;
			compareValues = (a, b) => {
				return a < b;
			};
		}

		for (let index of groupIndexTypes[group]) {
			if (compareValues(indexData[index].CategoryValue, maxGroupValue)) {
				maxGroupValue = indexData[index].CategoryValue;
				maxIndexType = index;
			}
		}
		activeIndices.push(maxIndexType);
	}
	return activeIndices;
}
