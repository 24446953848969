import { PersistentCacheImpl } from '@accuweather/accunodesdkcore/src/core/cache/PersistentCacheImpl';
import { HttpService } from '@accuweather/accunodesdkcore/src/core/http/HttpService';
import { LocationRouteResolverImpl } from '@accuweather/accunodesdk/src/locations/LocationRouteResolverImpl';
import { LocationServiceImpl } from '@accuweather/accunodesdk/src/locations/LocationServiceImpl';
import { ProductAvailabilityServiceImpl } from '@accuweather/accunodesdk/src/productavailability/ProductAvailabilityServiceImpl';
import { WeatherRouteResolverImpl } from '@accuweather/accunodesdk/src/weather/WeatherRouteResolverImpl';
import { WeatherServiceImpl } from '@accuweather/accunodesdk/src/weather/WeatherServiceImpl';
import { ContextualRouteResolverImpl } from '@accuweather/accunodesdk/src/contextual/ContextualRouteResolverImpl';
import { ContextualServiceImpl } from '@accuweather/accunodesdk/src/contextual/ContextualServiceImpl';

import {
	//setup settings
	CacheSettings,
	ApiSettings,
	SdkSettings,
} from '@accuweather/accunodesdkcore';

import {
	//api requests
	LocationByKeyRequest,
	CityByIpAddressRequest,
	CurrentConditionsByLocationKeyRequest,
	HourlyForecastsByLocationKeyRequest,
	MinuteForecastPremiumByLocationKeyRequest,
	MinuteForecastIntervalType,
	CurrentAirQualityByLocationKeyRequest,
	GroupIndexValuesByLocationKeyRequest,
} from '@accuweather/accunodesdk';

//setting up API services
let key = document.currentScript.dataset.key;
if (!key) {
	key = process.env.VUE_APP_API_KEY;
}
const cacheSettings = new CacheSettings();

const persistentCacheImpl = new PersistentCacheImpl();

const apiSettings = new ApiSettings(key);
apiSettings.timeoutPerTry = 30000;
apiSettings.overallTimeout = 90000;

const httpService = new HttpService(
	apiSettings,
	cacheSettings,
	persistentCacheImpl
);

const sdkSettings = new SdkSettings(apiSettings);

const locationRouteResolver = new LocationRouteResolverImpl(sdkSettings);

const locationService = new LocationServiceImpl(
	sdkSettings,
	httpService,
	locationRouteResolver
);

const productAvailabilityService = new ProductAvailabilityServiceImpl(
	sdkSettings,
	locationService
);

const weatherRouteResolver = new WeatherRouteResolverImpl(
	sdkSettings,
	productAvailabilityService,
	locationService
);

const weatherService = new WeatherServiceImpl(
	sdkSettings,
	httpService,
	weatherRouteResolver
);

const contextualRouteResolver = new ContextualRouteResolverImpl(
	sdkSettings,
	productAvailabilityService
);

const contextualService = new ContextualServiceImpl(
	sdkSettings,
	httpService,
	contextualRouteResolver
);

export async function getLocation(language, key) {
	const request = new LocationByKeyRequest(language, key);
	request.details = true; // Our code doesn"t need this, but the SDK does so this promotes cacheability
	const response = await locationService.getLocationByKey(request);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getCurrentConditions(language, key) {
	const request = new CurrentConditionsByLocationKeyRequest(language, key);
	request.isMetric = true;
	request.details = true;
	const response = await weatherService.getCurrentConditionsByLocationKey(
		request
	);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getHourly(countryID, key, language) {
	const request = new HourlyForecastsByLocationKeyRequest(language, key);
	request.isMetric = countryID != 'US';
	request.details = true;
	const response = await weatherService.getHourlyForecastsByLocationKey(
		request
	);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getMinuteCast(countryID, key, language) {
	const request = new MinuteForecastPremiumByLocationKeyRequest(language, key);
	request.isMetric = countryID != 'US';
	request.details = true;
	request.intervalType = MinuteForecastIntervalType.OneMinute;
	const response = await weatherService.getMinuteForecastPremiumByLocationKey(
		request
	);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getMinuteCastColors() {
	const request = {};
	const response = await weatherService.getMinuteColorRanges(request);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getCurrentAirQuality(key, language) {
	const request = new CurrentAirQualityByLocationKeyRequest(language, key);
	request.includePollutants = true;
	const response = await contextualService.getCurrentAirQuality(request);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getIndexValues(language, key, groupType) {
	const request = new GroupIndexValuesByLocationKeyRequest(
		language,
		key,
		groupType
	);
	request.details = true;
	const response = await contextualService.getGroupValuesByLocationKey(request);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export async function getCityByIpAddress(language, ip = '.') {
	const request = new CityByIpAddressRequest(language, ip, true);
	request.details = true;
	const response = await locationService.getCityByIpAddress(request);
	if (response.hasError) {
		return null;
	}
	return response.data;
}

export default {
	getLocation,
	getCurrentConditions,
	getHourly,
	getMinuteCast,
	getCurrentAirQuality,
	getCityByIpAddress,
	getMinuteCastColors,
	getIndexValues,
};
