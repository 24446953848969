<template>
	<div :class="[{ [$style['widget-vertical']]: !horizontal }, $style.widgets]">
		<div
			:class="[
				{ [$style.horizontal]: horizontal, [$style.slim]: slim },
				$style.app,
			]"
			v-if="!isLoading"
		>
			<AppHeader :slim="slim" />
			<div
				:class="[
					{ [$style.horizontal]: horizontal, [$style.slim]: slim },
					$style.widget,
				]"
			>
				<BaseWidget :widget="nextWidget" :slim="slim"></BaseWidget>
			</div>
		</div>
		<div v-if="layout !== 'responsive' && adtype !== 'none'">
			<ConnatixComponent
				v-if="adtype.includes('connatix')"
				:connatixPlayerId="connatixPlayerId"
				:layout="layout"
				:horizontal="horizontal"
				:responsiveHeight="responsiveHeight"
				:responsiveWidth="responsiveWidth"
				:adtype="adtype"
				:connatixId="connatixId"
			/>
			<SovrnComponent v-if="adtype === 'sovrn'" :client="sovrnClient" />
		</div>
	</div>
</template>

<script>
import { mapState, mapStores } from 'pinia';
import { locationStore } from '@/stores/location';
import { weatherStore } from '@/stores/weather';
import { widgetStore } from '@/stores/widget';

import BaseWidget from '@/components/Widgets/BaseWidget.vue';
import AppHeader from '@/components/AppHeader.vue';
import ConnatixComponent from '@/components/ConnatixComponent.vue';
import SovrnComponent from '@/components/SovrnComponent.vue';
import { setupMPulse } from '@/mpulse';

export default {
	name: 'App',
	props: [
		'layout',
		'slim',
		'partner',
		'strategy',
		'adtype',
		'lifestyle',
		'locationOverride',
		'connatixId',
		'connatixPlayerId',
		'sovrnClient',
	],
	data: () => ({
		isLoading: true,
		horizontal: false,
		responsiveWidth: 0,
		responsiveHeight: 0,
	}),
	created() {
		setupMPulse();
		switch (this.layout) {
			case 'responsive':
				this.setResponsiveDimensions();
				window.addEventListener('resize', this.setResponsiveDimensions);
				break;
			case 'horizontal':
				this.horizontal = true;
				break;
		}
	},
	unmount() {
		window.removeEventListener('resize', this.setResponsiveDimensions);
	},
	methods: {
		setResponsiveDimensions() {
			let awConnectAppElement = document.querySelector(
				'#accuWeatherConnectResponsiveApp'
			);
			if (awConnectAppElement.style.height === '') {
				awConnectAppElement.style.height = '100%';
			}
			this.responsiveHeight = awConnectAppElement.clientHeight;
			this.responsiveWidth = awConnectAppElement.clientWidth;
			if (
				this.responsiveWidth > this.responsiveHeight * 1.75 &&
				this.responsiveWidth > 450
			) {
				this.horizontal = true;
			} else {
				this.horizontal = false;
			}
		},
	},
	computed: {
		...mapState(weatherStore, ['currentConditionsData']),
		...mapState(widgetStore, ['nextWidget']),
		...mapStores(locationStore, weatherStore, widgetStore),
		backgroundSrc() {
			let imgPath;
			if (this.horizontal) {
				imgPath = `bg/600x250/${this.currentConditionsData?.WeatherIcon}`;
			} else {
				imgPath = `bg/300x350/${this.currentConditionsData?.WeatherIcon}`;
			}
			if (this.currentConditionsData?.IsDayTime) {
				imgPath += '_day.jpg';
			} else {
				imgPath += '_night.jpg';
			}
			return `url(${this.$image(imgPath)})`;
		},
		minWidth() {
			switch (this.layout) {
				case 'vertical':
					return '300px';
				case 'horizontal':
					return '600px';
				case 'responsive':
					return '285px';
				default:
					return '300px';
			}
		},
		width() {
			switch (this.layout) {
				case 'responsive':
					return '100%';
				default:
					return this.minWidth;
			}
		},
		height() {
			switch (this.layout) {
				case 'vertical':
					return this.slim ? '150px' : '350px';
				case 'horizontal':
					return '250px';
				case 'responsive':
					return '100%';
				default:
					return '350px';
			}
		},
		widgetHeightPlacement() {
			if (this.layout === 'responsive' && this.responsiveHeight >= 300) {
				if (this.horizontal) {
					return this.responsiveHeight - 190 + 'px';
				} else {
					return this.responsiveHeight - 147 + 'px';
				}
			} else if (this.layout === 'responsive' && this.responsiveHeight < 300) {
				return '150px';
			} else {
				return this.slim ? '38px' : '202px';
			}
		},
		widgetWidthPlacement() {
			if (this.layout === 'responsive' && !this.horizontal) {
				return '1%';
			} else {
				return '12px';
			}
		},
		widgetWidth() {
			if (this.layout === 'responsive' && !this.horizontal) {
				return '98%';
			} else {
				return '276px';
			}
		},
		minHeight() {
			if (this.layout === 'responsive' && !this.horizontal) {
				return 290 + 'px';
			} else if (this.layout === 'responsive' && this.horizontal) {
				return 220 + 'px';
			} else {
				return 0 + 'px';
			}
		},
		baseImage() {
			return `url(${this.$image('base-image.svg')})`;
		},
	},
	async mounted() {
		await this.locationStore.fetchCurrentlocation(this.locationOverride);
		await Promise.all([
			this.weatherStore.fetchMinutecastData(),
			this.weatherStore.fetchAirQualityData(),
			this.weatherStore.fetchCurrentConditionsData(),
			this.weatherStore.fetchHourlyForecastData(),
			this.weatherStore.fetchIndices(),
		]);

		let args = this.strategy.split(':');
		let strategyName = args.shift();
		this.widgetStore.setLayout(this.layout);
		this.widgetStore.setEligibleWidgets(strategyName, args[0], this.slim);
		this.isLoading = false;
	},
	components: { BaseWidget, AppHeader, ConnatixComponent, SovrnComponent },
};
</script>

<style lang="scss" scoped module>
.app {
	@import '@/assets/css/font.scss';
	position: relative;
	background-image: v-bind(backgroundSrc);
	background-repeat: no-repeat;
	* {
		color: #fff;
	}
	&::before {
		content: '';
		display: block;
		position: relative;
		z-index: -1;
		width: v-bind(width);
		height: v-bind(height);
		background-color: #aaa;
		background-image: v-bind(baseImage);
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 50%;
	}
	min-height: v-bind(minHeight);
	min-width: v-bind(minWidth);
	width: v-bind(width);
	height: v-bind(height) !important;
	background-size: cover;
	&.horizontal {
		display: inline-block;
		vertical-align: top;
	}
	&.slim {
		border-bottom: 1px solid #000000;
	}
}

.widgets {
	display: flex;
}

.widget-vertical {
	flex-direction: column;
}

.widget {
	position: absolute;
	top: v-bind(widgetHeightPlacement);
	right: v-bind(widgetWidthPlacement);
	width: v-bind(widgetWidth);
	height: 135px;
	&.horizontal {
		margin-top: auto;
		height: 178px;
		bottom: 12px;
	}
}
</style>
