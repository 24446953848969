<template>
	<p :class="[$style['minutecast-summary'], { [$style.slim]: slim }]">
		{{ summary }}
	</p>
	<div :class="$style['minutecast-content']">
		<svg :class="$style['minutecast-chart']" :height="chartHeight" width="100%">
			<rect
				v-for="(interval, index) in intervals"
				:key="index"
				:x="index * (intervalWidth + 1.1)"
				:y="chartHeight - getMinuteHeight(interval.Dbz)"
				:width="intervalWidth"
				:height="getMinuteHeight(interval.Dbz)"
				:fill="interval?.SimplifiedColor?.Hex ?? 'rgba(255,255,255,0.15)'"
			/>
		</svg>
		<div :class="$style['time-labels']">
			<span>{{ $t('widgets:common:Now') }}</span>
			<span>{{ $time(nextHour) }}</span>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { weatherStore } from '@/stores/weather';
import { locationStore } from '@/stores/location';
export default {
	name: 'MinuteCastWidget',
	props: ['slim'],
	data() {
		return {
			intervalWidth: 3,
			chartHeight: 30,
			label: 'See More',
			customTitle: 'MINUTECAST',
		};
	},
	computed: {
		...mapState(weatherStore, ['minutecastData']),
		...mapState(locationStore, ['currentLocation']),
		intervals() {
			return this.minutecastData?.Intervals?.slice(0, 60) ?? [];
		},
		url() {
			return this.minutecastData.Link;
		},
		nextHour() {
			return new Date(this.intervals[0]?.StartEpochDateTime + 3600 * 1000);
		},
		summary() {
			return this.minutecastData?.Summary?.Phrase_60 ?? '';
		},
	},
	methods: {
		getMinuteHeight(dbz) {
			//95 is the maximum dbz value, but we're using 75 as the cap for better representation
			return dbz == 0 ? 4 : (dbz * this.chartHeight) / 75;
		},
	},
};
</script>
<style lang="scss" scoped module>
.minutecast-content {
	margin-top: auto;
}
.minutecast-summary {
	font-size: 14px;
	font-weight: 500;
	margin-top: 16px;
	&.slim {
		margin-top: 7px;
	}
}
.minutecast-chart {
	width: 100%;
}
.time-labels {
	display: flex;
	justify-content: space-between;
	font-size: 10px;
}
</style>
