export const realFeelCategoriesArray = [
	{
		Title: 'Extraordinarily Dangerous Heat',
		Color: '#811518',
		Values: {
			Imperial: {
				High: 140,
				Low: 133,
			},
			Metric: {
				High: 60,
				Low: 56,
			},
		},
		ContentCode: 'ExtraordinarilyDangerousHeatDescription1',
	},
	{
		Title: 'Extremely Dangerous Heat',
		Color: '#a91f23',
		Values: {
			Imperial: {
				High: 132,
				Low: 125,
			},
			Metric: {
				High: 55,
				Low: 51,
			},
		},
		ContentCode: 'ExtremelyDangerousHeatDescription1',
	},
	{
		Title: 'Very Dangerous Heat',
		Color: '#ce2527',
		Values: {
			Imperial: {
				High: 124,
				Low: 116,
			},
			Metric: {
				High: 50,
				Low: 46,
			},
		},
		ContentCode: 'VeryDangerousHeatDescription1',
	},
	{
		Title: 'Dangerous Heat',
		Color: '#f15d22',
		Values: {
			Imperial: {
				High: 115,
				Low: 108,
			},
			Metric: {
				High: 45,
				Low: 42,
			},
		},
		ContentCode: 'HotDescription',
	},
	{
		Title: 'Quite Hot',
		Color: '#f6891f',
		Values: {
			Imperial: {
				High: 107,
				Low: 101,
			},
			Metric: {
				High: 41,
				Low: 38,
			},
		},
		ContentCode: 'QuiteHotDescription',
	},
	{
		Title: 'Hot',
		Color: '#fbaf17',
		Values: {
			Imperial: {
				High: 100,
				Low: 90,
			},
			Metric: {
				High: 37,
				Low: 32,
			},
		},
		ContentCode: 'QuiteHotDescription',
	},
	{
		Title: 'Very Warm',
		Color: '#c8be2b',
		Values: {
			Imperial: {
				High: 89,
				Low: 82,
			},
			Metric: {
				High: 31,
				Low: 27,
			},
		},
		ContentCode: 'QuiteHotDescription',
	},
	{
		Title: 'Pleasant',
		Color: '#8bb23e',
		Values: {
			Imperial: {
				High: 81,
				Low: 63,
			},
			Metric: {
				High: 26,
				Low: 17,
			},
		},
		ContentCode: 'PleasantDescription',
	},
	{
		Title: 'Cool',
		Color: '#549d44',
		Values: {
			Imperial: {
				High: 62,
				Low: 53,
			},
			Metric: {
				High: 16,
				Low: 11,
			},
		},
		ContentCode: 'CoolDescription',
	},
	{
		Title: 'Chilly',
		Color: '#259546',
		Values: {
			Imperial: {
				High: 52,
				Low: 40,
			},
			Metric: {
				High: 10,
				Low: 4,
			},
		},
		ContentCode: 'ChillyDescription',
	},
	{
		Title: 'Cold',
		Color: '#1e8c68',
		Values: {
			Imperial: {
				High: 39,
				Low: 25,
			},
			Metric: {
				High: 3,
				Low: -3,
			},
		},
		ContentCode: 'ColdDescription',
	},
	{
		Title: 'Very Cold',
		Color: '#21798c',
		Values: {
			Imperial: {
				High: 24,
				Low: 10,
			},
			Metric: {
				High: -4,
				Low: -12,
			},
		},
		ContentCode: 'VeryColdDescription',
	},
	{
		Title: 'Quite Cold',
		Color: '#21798c',
		Values: {
			Imperial: {
				High: 9,
				Low: -10,
			},
			Metric: {
				High: -13,
				Low: -23,
			},
		},
		ContentCode: 'QuiteColdDescription1',
	},
	{
		Title: 'Bitterly Cold',
		Color: '#376793',
		Values: {
			Imperial: {
				High: -11,
				Low: -24,
			},
			Metric: {
				High: -24,
				Low: -31,
			},
		},
		ContentCode: 'BitterlyColdDescription1',
	},
	{
		Title: 'Dangerous Cold',
		Color: '#53468e',
		Values: {
			Imperial: {
				High: -25,
				Low: -43,
			},
			Metric: {
				High: -32,
				Low: -41,
			},
		},
		ContentCode: 'DangerousColdDescription1',
	},
	{
		Title: 'Very Dangerous Cold',
		Color: '#5a3881',
		Values: {
			Imperial: {
				High: -44,
				Low: -69,
			},
			Metric: {
				High: -42,
				Low: -56,
			},
		},
		ContentCode: 'VeryDangerousColdDescription1',
	},
	{
		Title: 'Extremely Dangerous Cold',
		Color: '#472966',
		Values: {
			Imperial: {
				High: -70,
				Low: -90,
			},
			Metric: {
				High: -57,
				Low: -67,
			},
		},
		ContentCode: 'ExtremelyDangerousColdDescription1',
	},
	{
		Title: 'Extraordinarily Dangerous Cold',
		Color: '#2e1644',
		Values: {
			Imperial: {
				High: -91,
				Low: -120,
			},
			Metric: {
				High: -68,
				Low: -84,
			},
		},
		ContentCode: 'ExtraordinarilyDangerousColdDescription1',
	},
];
