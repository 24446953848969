import { defineStore } from 'pinia';
import {
	getMinuteCast,
	getCurrentAirQuality,
	getHourly,
	getCurrentConditions,
	getMinuteCastColors,
	getIndexValues,
} from '@/api/accuweather';
import { IndexGroupType } from '@accuweather/accunodesdk';
import { locationStore } from './location';
import { getActiveIndices } from '@/services/lifestyleService';

export const weatherStore = defineStore('weather', {
	state: () => ({
		minutecastData: {},
		currentConditionsData: {},
		minutecastColors: {},
		airQualityData: {},
		hourlyData: [],
		indexGroup: IndexGroupType.WebAll,
		indexData: {},
	}),
	getters: {
		key() {
			return locationStore().currentLocation.Key;
		},
		countryID() {
			return locationStore().currentLocation.Country.ID;
		},
		activeIndexTypes() {
			return getActiveIndices(this.indexData);
		},
	},
	actions: {
		async fetchMinutecastData() {
			return getMinuteCast(
				this.countryID,
				this.key,
				window.aw_connect_language
			).then((minutecastData) => {
				if (minutecastData) window.accuweather.minutecastData = minutecastData;
				this.minutecastData = minutecastData;
			});
		},
		async fetchCurrentConditionsData() {
			return getCurrentConditions(window.aw_connect_language, this.key).then(
				(currentConditionsData) => {
					if (currentConditionsData)
						window.accuweather.currentConditionsData = currentConditionsData;
					this.currentConditionsData = currentConditionsData;
				}
			);
		},
		async fetchMinutecastColors() {
			return getMinuteCastColors().then((minutecastColors) => {
				this.minutecastColors = minutecastColors.reduce((previous, current) => {
					if (!previous[current.Type]) {
						previous[current.Type] = [];
					}
					previous[current.Type].push(current);
					return previous;
				}, {});
			});
		},
		async fetchAirQualityData() {
			return getCurrentAirQuality(this.key, window.aw_connect_language).then(
				(airQualityData) => {
					if (airQualityData)
						window.accuweather.airQualityData = airQualityData;
					this.airQualityData = airQualityData;
				}
			);
		},
		async fetchHourlyForecastData() {
			return getHourly(
				this.countryID,
				this.key,
				window.aw_connect_language
			).then((hourlyData) => {
				if (hourlyData) window.accuweather.hourlyData = hourlyData;
				this.hourlyData = hourlyData;
			});
		},
		async fetchIndices() {
			return getIndexValues(
				window.aw_connect_language,
				this.key,
				this.indexGroup
			).then((values) => {
				if (values) window.accuweather.indexData = values;
				values.forEach((indexData) => {
					this.indexData[indexData.ID] = indexData;
				});
			});
		},
	},
});
