<template>
	<div :class="$style.backdrop" />
	<a
		disabled
		:href="url"
		target="_blank"
		rel="noopener noreferrer"
		:class="{
			[$style['base-container']]: widget !== 'CurrentConditionsWidget',
			[$style.slim]: slim,
		}"
	>
		<div
			:class="$style.header"
			v-if="!headless && !slim && widget !== 'CurrentConditionsWidget'"
		>
			<div :class="$style.title" v-html="title" />
			<div :class="$style.link">
				<span :class="$style['see-more']">{{
					$t(`widgets:common:${label}`)
				}}</span>
				<img
					:class="$style['arrow-icon']"
					:src="$image('icons/arrow-right-black.png')"
					alt="arrow"
				/>
			</div>
		</div>
		<div :class="$style.body">
			<component
				:is="widget"
				:key="widget"
				:slim="slim"
				ref="childComponent"
			></component>
		</div>
	</a>
</template>

<script>
import { mapState } from 'pinia';
import { locationStore } from '@/stores/location';
import { weatherStore } from '@/stores/weather';
import MinuteCastWidget from './MinuteCastWidget.vue';
import HourlyForecastWidget from './HourlyForecastWidget.vue';
import RealFeelWidget from './RealFeelWidget.vue';
import MapWidget from './MapWidget.vue';
import AirQualityWidget from './AirQualityWidget.vue';
import LifeStyleWidget from './LifeStyleWidget.vue';
import CurrentConditionsWidget from './CurrentConditionsWidget.vue';

export default {
	name: 'BaseWidget',
	props: ['widget', 'slim'],
	components: {
		MinuteCastWidget,
		HourlyForecastWidget,
		RealFeelWidget,
		AirQualityWidget,
		MapWidget,
		LifeStyleWidget,
		CurrentConditionsWidget,
	},
	data() {
		return { isMounted: false };
	},
	mounted() {
		this.isMounted = true;
	},
	computed: {
		...mapState(locationStore, ['currentLocation', 'locationTitle']),
		...mapState(weatherStore, ['currentConditionsData']),
		label() {
			return this.isMounted ? this.$refs.childComponent.label : '';
		},
		url() {
			let url = new URL('https://www.accuweather.com/');
			if (this.isMounted && !!this.$refs.childComponent.url) {
				url = new URL(this.$refs.childComponent.url);
			}
			url.searchParams.set('p', this.$root.partner);
			return url;
		},
		title() {
			if (this.isMounted && !!this.$refs.childComponent.customTitle) {
				return this.$t(
					`widgets:common:${this.$refs.childComponent.customTitle}`
				).toUpperCase();
			} else {
				return this.locationTitle;
			}
		},
		headless() {
			if (this.isMounted && !!this.$refs.childComponent.headless) {
				return this.$refs.childComponent.headless;
			} else {
				return false;
			}
		},
		backgroundImage() {
			return this.$root.backgroundSrc;
		},
	},
};
</script>

<style lang="scss" scoped module>
.base-container {
	&.slim {
		height: 100px;
	}
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 6px;
	padding: 8px;
	text-decoration: none;
	background-color: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(25px);
	.header .title {
		font-size: 10px;
		opacity: 0.6;
	}
	.link {
		font-size: 12px;
		font-weight: normal;
	}
	.title {
		font-weight: 500;
	}
	&,
	&:hover,
	&:focus,
	&:active,
	&:visited,
	&:hover:focus {
		color: #fff;
	}
}

.backdrop {
	display: none;
}

@supports not (backdrop-filter: blur(25px)) {
	.backdrop {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 6px;
		overflow: hidden;
		&::before {
			display: block;
			filter: blur(25px);
			content: '';
			background-image: v-bind(backgroundImage);
			background-attachment: fixed;
			min-height: 100vh;
		}
	}
	.base-container {
		position: absolute;
		width: 100%;
	}
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.title {
	font-size: 12px;
	line-height: 2.5;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
}

.link {
	display: flex;
	align-items: center;
	font-size: 10px;
	font-weight: 600;

	.see-more {
		margin-right: 4px;
		margin-left: auto;
	}

	.arrow-icon {
		flex-shrink: 0;
		filter: invert(100%);
		width: 16px;
		height: 16px;
	}
}
</style>
