<template>
	<div :class="[$style.map, { [$style.slim]: slim }]" />
</template>

<script>
import { mapState } from 'pinia';
import { locationStore } from '@/stores/location';
export default {
	name: 'MapWidget',
	data() {
		return {
			headless: true,
		};
	},
	props: ['slim'],
	computed: {
		...mapState(locationStore, ['radarSrc', 'currentLocation']),
		radar() {
			const height = this.$root.horizontal ? 170 : 130;
			return `url(${this.radarSrc(260, height)})`;
		},
		url() {
			const language = window.aw_connect_language.split('-')[0];
			if (
				!!this.currentLocation?.Key &&
				!!this.currentLocation?.LocalizedName
			) {
				const postalCode = !!this.currentLocation?.PrimaryPostalCode
					? this.currentLocation.PrimaryPostalCode
					: this.currentLocation.Key;
				return `https://www.accuweather.com/${language}/${this.currentLocation.LocalizedName}/${postalCode}/weather-radar/${this.currentLocation.Key}`;
			} else {
				return `https://www.accuweather.com/${language}/us/national/weather-radar`;
			}
		},
	},
};
</script>
<style lang="scss" scoped module>
.map {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 6px;
	margin: -8px;
	background-image: v-bind(radar);
	background-size: cover;
	background-repeat: no-repeat;
	&.slim {
		height: 100px;
		overflow: hidden;
	}
}
</style>
