<template>
	<div :class="$style.realfeel">
		<div :class="[$style.temperature, { [$style.slim]: slim }]">
			{{ Math.round(temperature.Value)
			}}<span :class="$style.degree">&deg;</span
			><span :class="$style.unit">
				{{ temperature.Unit }}
			</span>
		</div>
		<div :class="[$style['realfeel-text'], { [$style.slim]: slim }]">
			<div :class="$style['realfeel-title']">
				{{ $t(`widgets:realfeel:${currentConditionsRealFeelCategory?.Title}`) }}
			</div>
			<div :class="$style['realfeel-line']" />
			<p :class="$style['content-text']">
				{{ realFeelText }}
			</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { weatherStore } from '@/stores/weather';
import { getRealFeelCategoryValue } from '@/services/weatherService';
import { locationStore } from '@/stores/location';
export default {
	name: 'RealFeelWidget',
	props: ['slim'],
	data() {
		return {
			label: 'See More',
			customTitle: 'RealFeel',
		};
	},
	computed: {
		...mapState(weatherStore, ['currentConditionsData']),
		...mapState(locationStore, ['currentLocation']),
		currentConditionsRealFeelCategory: function () {
			return getRealFeelCategoryValue(this.currentConditionsData);
		},
		realFeelText() {
			const realFeelText = this.$t(
				`widgets:realfeel:${this.currentConditionsRealFeelCategory?.ContentCode}`
			);
			return realFeelText.substring(realFeelText.lastIndexOf('. ') + 1);
		},
		url() {
			return this.currentConditionsData?.Link ?? '';
		},
		temperature() {
			const temperature = this.currentConditionsData?.RealFeelTemperature;
			const countryID = this.currentLocation?.Country?.ID;
			return countryID === 'US' ? temperature?.Imperial : temperature?.Metric;
		},
		realFeelColor() {
			return this.currentConditionsRealFeelCategory?.Color;
		},
	},
};
</script>
<style lang="scss" scoped module>
.realfeel {
	display: flex;
	justify-content: center;
	vertical-align: middle;
	width: 100%;
	overflow: hidden;
	margin-top: 9px;
}
.realfeel-line {
	width: 32px;
	margin: 8px 0px;
	border: 0px;
	height: 3px;
	background-color: v-bind(realFeelColor);
}
.temperature {
	font-size: 39px;
	margin: 33px 35px;
	margin-top: 15px;
	.degree {
		font-size: 28px;
		vertical-align: text-top;
		line-height: 37px;
		margin-left: 2px;
	}
	.unit {
		font-size: 16px;
		margin-left: -9px;
		opacity: 0.6;
	}
	&.slim {
		flex-grow: 1;
		text-align: center;
		margin: 12px 8px 0px 8px;
	}
}
.realfeel-text {
	display: flex;
	flex-direction: column;
	width: 146px;
	margin-left: 0;
	.realfeel-title {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
	}
	.content-text {
		font-size: 11px;
		line-height: 14px;
		height: 42px;
		overflow: hidden;
	}
	&.slim {
		flex-grow: 2;
	}
}
</style>
