import { defineStore } from 'pinia';
import {
	getStandardWidgets,
	getRoundRobinWidgets,
	getSpecificWidget,
	getRandomWidget,
} from '@/services/widgetService';

export const widgetStore = defineStore('widget', {
	state: () => ({
		eligibleWidgets: [],
		layout: '',
		connatixActive: false,
	}),
	getters: {
		nextWidget() {
			let nextWidget = '';
			let storageKey = '';
			switch (this.layout) {
				case 'vertical':
					storageKey = 'currentWidget';
					break;
				case 'horizontal':
					storageKey = 'currentHorizontalWidget';
					break;
				case 'responsive':
					storageKey = 'currentResponsiveWidget';
					break;
			}
			const currentWidget = localStorage[storageKey];

			const currentWidgetIndex = this.eligibleWidgets.findIndex(
				(widget) => widget === currentWidget
			);
			const nextIndex = (currentWidgetIndex + 1) % this.eligibleWidgets.length;
			nextWidget = this.eligibleWidgets[nextIndex];

			localStorage[storageKey] = nextWidget;
			return nextWidget;
		},
	},
	actions: {
		setEligibleWidgets(strategyName, widget, slim) {
			switch (strategyName) {
				case 'random':
					this.eligibleWidgets.push(getRandomWidget(slim));
					break;
				case 'round-robin':
					this.eligibleWidgets.push(...getRoundRobinWidgets(slim));
					break;
				case 'specific': {
					let specificWidget = getSpecificWidget(widget, slim);
					if (Array.isArray(specificWidget)) {
						this.eligibleWidgets.push(...specificWidget);
					} else {
						this.eligibleWidgets.push(specificWidget);
					}
					break;
				}
				case 'standard':
				default:
					this.eligibleWidgets.push(...getStandardWidgets(slim));
					break;
			}
		},
		setLayout(layout) {
			this.layout = layout;
		},
		setConnatixActive(isConnatixActive) {
			this.connatixActive = isConnatixActive;
		},
	},
});
