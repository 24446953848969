import { ApiSettings } from '@accuweather/accunodesdkcore/src/core/settings/ApiSettings';
import { SdkSettings } from '@accuweather/accunodesdkcore/src/core/settings/SdkSettings';
import { DateFormatOptions } from '@accuweather/accunodesdk/src/i18n/options/dates/DateFormatOptions';
import { CustomDateFormatOptions } from '@accuweather/accunodesdk/src/i18n/options/dates/CustomDateFormatOptions';
import { DateTimeFormat } from '@accuweather/accunodesdk/src/i18n/options/dates/DateTimeFormat';
import { DateTimeFormatWidth } from '@accuweather/accunodesdk/src/i18n/options/dates/DateTimeFormatWidth';
import { LocalizationServiceImpl } from '@accuweather/accunodesdk/src/i18n/LocalizationServiceImpl';
const enUsDates = require('@accuweather/accunodesdk/bin/config/dates/en-us.json');
const enUsUnits = require('@accuweather/accunodesdk/bin/config/units/en-us.json');
import translationEng from '../locales/translations/translations.en-us.json';

export default class I18NService {
	timeout = 1000; // timeout in milliseconds
	localizationService;
	dates = new Map();
	units = new Map();
	language = 'en-us';
	translations = {};

	constructor() {
		this.localizationService = new LocalizationServiceImpl(
			this.buildSdkSettings()
		);
		this.dates.set('en-us', enUsDates);
		this.units.set('en-us', enUsUnits);
	}

	buildSdkSettings() {
		let key = document.currentScript.dataset.key;
		if (!key) {
			key = process.env.VUE_APP_API_KEY;
		}

		const apiSettings = new ApiSettings(key);
		apiSettings.timeoutPerTry = 30000;
		apiSettings.overallTimeout = 90000;

		return new SdkSettings(apiSettings);
	}

	async setup(language) {
		this.language = language;
		await this.setupLocalization(language);
		this.translations = await this.setupTranslations(language);
	}

	async setupTranslations(language) {
		if (language === 'en-us') return translationEng;
		else {
			try {
				const response = await fetch(
					`${process.env.VUE_APP_HOST}${process.env.BASE_URL}locales/translations/translations.${language}.json`
				);
				return await response.json();
			} catch {
				const languageCode = language.split('-');
				if (languageCode === 'en' || languageCode.length === 1)
					return translationEng;
				return await this.setupTranslations(languageCode[0]);
			}
		}
	}

	async setupLocalization(language) {
		if (language !== 'en-us') {
			const date = await this.getLocalizationFile(language, 'dates');
			const unit = await this.getLocalizationFile(language, 'units');

			if (date !== null && unit !== null) {
				this.dates.set(language, date);
				this.units.set(language, unit);
			}
		}

		this.localizationService.setLanguages(this.dates, this.units);
	}

	async getLocalizationFile(language, folder) {
		if (language === 'en-us') return;
		try {
			const response = await fetch(
				`${process.env.VUE_APP_HOST}${process.env.BASE_URL}${folder}/${language}.json`
			);
			return await response.json();
		} catch {
			const languageCode = language.split('-');
			if (languageCode === 'en' || languageCode.length === 1) return;
			return this.getLocalizationFile(languageCode[0]);
		}
	}

	getTranslation(key, args) {
		const translation =
			this.translations[`com:accuweather:${key}`] ??
			translationEng[`com:accuweather:${key}`] ??
			key.split(':').pop();
		return this.setTranslationVariables(translation, args);
	}

	setTranslationVariables(literal, args) {
		for (let arg in args) {
			literal = literal.replace(`{${arg}}`, args[arg]);
		}
		return literal;
	}

	formatHour(date) {
		return this.localizationService.datetimeCustom(
			date,
			new CustomDateFormatOptions(this.language, DateTimeFormat.NarrowTime)
		);
	}

	formatTime(date) {
		return this.localizationService.time(
			date,
			new DateFormatOptions(this.language, DateTimeFormatWidth.Short)
		);
	}
}
