<template>
	<div :class="$style['app-header']">
		<div :class="$style['weather-data']">
			<div :class="$style['location-title']">{{ locationTitle }}</div>
			<span v-if="!slim">
				<div :class="$style['temperature-container']">
					<BaseWidget :widget="'CurrentConditionsWidget'"></BaseWidget>
				</div>
			</span>
		</div>
		<a
			:class="$style['logo-container']"
			href="https://www.accuweather.com/"
			target="_blank"
		>
			<img
				:src="$image('logos/logos-primary-landscape-white-small.svg')"
				alt="accuweather-logo"
			/>
		</a>
	</div>
</template>
<script>
import { mapState } from 'pinia';
import { locationStore } from '@/stores/location';
import { weatherStore } from '@/stores/weather';
import BaseWidget from '@/components/Widgets/BaseWidget.vue';

export default {
	props: ['slim'],
	computed: {
		...mapState(locationStore, ['currentLocation', 'locationTitle']),
		...mapState(weatherStore, ['currentConditionsData']),
		weatherIcon() {
			const icon = this.currentConditionsData?.WeatherIcon;
			return !!icon ? `icons/weather_icons/${icon}.svg` : null;
		},
		weatherText() {
			return this.currentConditionsData?.WeatherText;
		},
		temperature() {
			const temperature = this.currentConditionsData?.Temperature;
			const countryID = this.currentLocation?.Country?.ID;
			return countryID === 'US' ? temperature?.Imperial : temperature?.Metric;
		},
	},
	components: { BaseWidget },
};
</script>
<style lang="scss" scoped module>
.app-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	color: #fff;
	.weather-data {
		display: flex;
		flex-direction: column;
		.location-title {
			font-size: 12px;
			line-height: 16px;
		}
	}
	.weather-icon {
		width: 64px;
	}
	.temperature-container {
		display: flex;
		align-items: flex-start;
		margin-top: 32px;
	}
	.temperature {
		margin-left: 12px;
		font-size: 60px;
		line-height: 60px;
		letter-spacing: -0.5px;
	}
	.temperature-unit-container {
		display: flex;
		flex-direction: column;
		font-size: 40px;
		margin-top: 32px;
		.degree {
			line-height: 0.5;
			margin-top: 13px;
		}
	}
	.temperature-unit {
		font-size: 18px;
		line-height: 18px;
		opacity: 0.5;
	}
	.weather-text {
		font-size: 18px;
		line-height: 22px;
	}
	.logo-container {
		height: 16px;
		display: flex;
		align-items: center;
	}
}
</style>
