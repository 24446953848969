<template>
	<div :class="[$style['hourly-forecast-container'], { [$style.slim]: slim }]">
		<div
			:class="$style['hour-forecast']"
			v-for="(hourForecast, index) in forecastData"
			:key="index"
		>
			<div :class="$style['hour-display']">
				{{ formattedHour(hourForecast) }}
			</div>
			<img
				:class="$style['weather-icon']"
				:src="`https://www.accuweather.com/images/weathericons/${hourForecast?.WeatherIcon}.svg`"
				:alt="hourForecast?.IconPhrase"
			/>
			<span :class="$style['temperature']"
				>{{ Math.round(hourForecast?.Temperature?.Value) }}&deg;</span
			>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { weatherStore } from '@/stores/weather';
import { locationStore } from '@/stores/location';
export default {
	name: 'HourlyForecastWidget',
	props: ['slim'],
	data() {
		return {
			label: 'See More',
			customTitle: 'Hourly',
		};
	},
	computed: {
		...mapState(weatherStore, ['hourlyData']),
		...mapState(locationStore, ['currentLocation']),
		url() {
			return this.hourlyData[0]?.Link ?? '';
		},
		forecastData() {
			return this.hourlyData ? this.hourlyData.slice(0, 6) : [];
		},
	},
	methods: {
		formattedHour(hour) {
			return this.$hour(new Date(hour.EpochDateTime * 1000));
		},
	},
};
</script>
<style lang="scss" scoped module>
.hourly-forecast-container {
	display: flex;
	justify-content: space-between;
	text-align: center;
	margin-top: 9px;
	margin-bottom: 7px;
	height: 100%;
	font-size: 10px;
	font-weight: 500;
	&.slim {
		margin-top: 1px;
	}
	.hour-forecast {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.hour-display {
		height: 20px;
		line-height: 3;
	}
	.temperature {
		font-size: 16px;
		font-weight: bold;
	}
	.weather-icon {
		width: 27px;
		height: 27px;
		object-fit: contain;
		filter: brightness(0) invert(1);
	}
}
</style>
