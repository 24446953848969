<template>
	<div
		v-if="adtype === 'connatix-elements'"
		:class="[
			$style.adContainer,
			{ [$style.elementHorizontal]: layout === 'horizontal' },
			{
				[$style.elementResponsiveVertical]:
					layout === 'responsive' && !horizontal,
			},
			{
				[$style.elementResponsiveHorizontal]:
					layout === 'responsive' && horizontal,
			},
		]"
	>
		<div :id="adId" :ref="adId">
			<img
				:src="
					'https://capi.connatix.com/tr/si?token=' +
					connatixPlayerId +
					'&cid=' +
					connatixId
				"
				alt=""
			/>
		</div>
	</div>
	<div
		v-else-if="adtype === 'connatix-playspace'"
		:class="[
			$style.adContainer,
			{ [$style.playspaceHorizontal]: layout === 'horizontal' },
		]"
	>
		<div :id="adId" :ref="adId">
			<img
				:src="
					'https://capi.connatix.com/tr/si?token=' +
					connatixPlayerId +
					'&cid=' +
					connatixId
				"
				alt=""
			/>
		</div>
	</div>
</template>
<script>
import { mapState, mapStores } from 'pinia';
import { widgetStore } from '@/stores/widget';

export default {
	props: [
		'layout',
		'horizontal',
		'responsiveHeight',
		'responsiveWidth',
		'adtype',
		'connatixId',
		'connatixPlayerId',
	],
	data() {
		return {
			adId: 'cnxAd' + this.layout,
		};
	},
	computed: {
		...mapState(widgetStore, ['connatixActive']),
		...mapStores(widgetStore),
		adWidth() {
			if (this.layout !== 'responsive') {
				return '300px';
			} else {
				return this.responsiveWidth + 'px';
			}
		},
		display() {
			if (this.horizontal) {
				return 'inline-block';
			} else {
				return 'block';
			}
		},
		height() {
			if (!this.horizontal) {
				switch (this.adtype) {
					case 'connatix-elements':
						return '170px';
					case 'connatix-playspace':
						return '250px';
					default:
						return '0px';
				}
			} else {
				return '0px';
			}
		},
		horizontalAdPlacement() {
			return this.responsiveWidth + 'px';
		},
		horizontalAdWidth() {
			if (this.horizontal) {
				return this.responsiveHeight * 1.775 + 'px';
			} else {
				return this.responsiveWidth + 'px';
			}
		},
	},
	mounted() {
		if (!this.connatixActive) {
			if (this.adtype === 'connatix-elements') {
				window.cnx.cmd.push(() => {
					window.cnx({ playerId: this.connatixPlayerId }).render(this.adId);
				});
			} else {
				window.cnxps.cmd.push(() => {
					window.cnxps({ playerId: this.connatixPlayerId }).render(this.adId);
				});
			}
		}
		this.widgetStore.setConnatixActive(true);
	},
};
</script>

<style lang="scss" scoped module>
.adContainer {
	width: 300px;
	display: v-bind(display);
	height: v-bind(height);
	&.elementHorizontal {
		min-width: 445px;
		height: 250px;
	}
	&.elementResponsiveVertical {
		left: v-bind(horizontalAdPlacement);
		width: v-bind(horizontalAdWidth);
	}
	&.elementResponsiveHorizontal {
		left: v-bind(horizontalAdPlacement);
		width: v-bind(horizontalAdWidth);
		height: v-bind(responsiveHeight);
	}
	&.playspaceHorizontal {
		min-width: 300px;
		height: 250px;
	}
}
</style>
