import { createApp } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import { registerErrorHandler } from '@/services/errorService';
import imagePlugin from '@/plugins/imagePlugin';
import { connatixSetup } from './connatix';
import translationPlugin from '@/plugins/translationPlugin';
import localizationPlugin from '@/plugins/localizationPlugin';
import I18NService from '@/services/I18NService';

registerErrorHandler(App);
const partner = document.currentScript.dataset.partner ?? 'connect';
const slim =
	document.currentScript.dataset.slim?.toLowerCase() === 'true' ?? false;
const strategy = document.currentScript.dataset.strategy ?? '';
const locationOverride = document.currentScript.dataset.location;
const lifestyle = document.currentScript.dataset.lifestyle;
const connatixId =
	document.currentScript.dataset.connatixid ||
	process.env.VUE_APP_GENERIC_CONNATIX_ID;
const connatixPlayerId = document.currentScript.dataset.connatixplayerid;
const language = document.currentScript.dataset.language;
const sovrnClient = document.currentScript.dataset.sovrnclient;

if (!language || language === 'browser') {
	window.aw_connect_language = navigator.language;
} else {
	window.aw_connect_language = language;
}
window.aw_connect_language = window.aw_connect_language.toLowerCase();

let adtype = document.currentScript.dataset.adtype;

if (!window.accuweather) window.accuweather = {};

if (
	(adtype === 'connatix-elements' || adtype === 'connatix-playspace') &&
	connatixId &&
	connatixPlayerId
) {
	connatixSetup(adtype, connatixId);
} else if (adtype === 'sovrn' && sovrnClient) {
	adtype = 'sovrn';
} else {
	adtype = 'none';
}

const i18nService = new I18NService();
await i18nService.setup(window.aw_connect_language);

const mountApp = (id, layout, hasSlim) => {
	if (document.querySelector(id)) {
		const app = createApp(App, {
			layout: layout,
			slim: hasSlim ? slim : false,
			partner,
			strategy,
			adtype,
			locationOverride,
			lifestyle,
			connatixId,
			connatixPlayerId,
			sovrnClient,
		})
			.use(createPinia())
			.use(imagePlugin)
			.use(translationPlugin, i18nService)
			.use(localizationPlugin, i18nService);
		app.mount(id);
	}
};

mountApp('#accuWeatherConnectApp', 'vertical', true);
mountApp('#accuWeatherConnectHorizontalApp', 'horizontal', false);
mountApp('#accuWeatherConnectResponsiveApp', 'responsive', false);
