<template>
	<div :class="[$style.lifestyle, { [$style.slim]: slim }]">
		<div :class="$style['activity-icon']">
			<img :src="$image(indexIcon)" alt="" />
		</div>
		<div :class="$style['activity-text']">
			<div :class="$style['activity-name']">
				{{ indexName }}
			</div>
			<div :class="$style['category-line']" />
			<p :class="$style['category-text']">
				{{ indexPhrase }}
			</p>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { weatherStore } from '@/stores/weather';
import { IndexType } from '@accuweather/accunodesdk';

import {
	getColor,
	lifestyleIndexInfo,
	getLink,
	getPhrase,
} from '@/services/lifestyleService';
export default {
	name: 'LifeStyleWidget',
	props: ['slim'],
	data() {
		return {
			label: 'See All',
			frosted: true,
			customTitle: 'Health And Activities',
			currentIndexType: 0,
			CATEGORY_VALUE_LENGTH: 6,
		};
	},
	computed: {
		...mapState(weatherStore, ['indexData', 'activeIndexTypes', 'key']),
		selectedIndex() {
			if (!!lifestyleIndexInfo[this.$root.lifestyle]) {
				return this.indexData[this.$root.lifestyle];
			}
			const selectedIndexType = this.activeIndexTypes[this.currentIndexType];
			return this.indexData[selectedIndexType] ?? {};
		},
		url() {
			const baseLink = this.indexData[IndexType.Running].Link;
			return getLink(this.selectedIndex, baseLink, 'running-weather');
		},
		indexName() {
			return this.$t(
				`widgets:lifestyle:${lifestyleIndexInfo[this.selectedIndex.ID].name}`
			);
		},
		indexIcon() {
			const icon = lifestyleIndexInfo[this.selectedIndex.ID].icon;
			return !!icon ? `icons/lifestyle/${icon}.svg` : null;
		},
		indexColor() {
			return getColor(this.selectedIndex);
		},
		indexPhrase() {
			return this.$t(`widgets:common:${getPhrase(this.selectedIndex)}`);
		},
	},
	mounted() {
		if (!lifestyleIndexInfo[this.$root.lifestyle]) {
			let storageKey = 'AWConnectLifestyleIndex';
			if (this.$root.layout === 'horizontal') {
				storageKey = 'AWConnectHorizontalLifestyleIndex';
			} else if (this.$root.layout === 'responsive') {
				storageKey = 'AWConnectResponsiveLifestyleIndex';
			}
			const currentIndex = localStorage[storageKey] ?? 0;
			const nextIndex =
				(Math.abs(currentIndex) + 1) % this.activeIndexTypes.length;
			this.currentIndexType = nextIndex;
			localStorage[storageKey] = nextIndex;
		}
	},
};
</script>
<style lang="scss" scoped module>
.lifestyle {
	display: flex;
	width: 100%;
	margin-top: 20px;
	&.slim {
		margin: auto;
	}
	.category-line {
		width: 32px;
		margin: 8px 0px;
		border: 0px;
		height: 3px;
		background-color: v-bind(indexColor);
	}
}
.activity-icon {
	margin-top: 4px;
	margin-left: 25px;
	img {
		width: 48px;
		height: 48px;
	}
}
.activity-text {
	display: flex;
	flex-direction: column;
	margin-left: 33px;
	margin-right: auto;
	.activity-name {
		font-size: 12px;
		line-height: 20px;
	}
	.category-text {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
	}
}
</style>
