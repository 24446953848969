<template>
	<div class="sovrn-ad-1"></div>
</template>

<script>
export default {
	props: ['client'],
	mounted() {
		const sovrnScript = document.createElement('script');
		sovrnScript.setAttribute('src', this.scriptSrc);
		sovrnScript.setAttribute('async', '');
		document.head.appendChild(sovrnScript);
	},
	computed: {
		scriptSrc() {
			return `https://global.proper.io/${this.client}.min.js`;
		},
	},
};
</script>
