const localizationPlugin = {
	install(app, i18nService) {
		app.config.globalProperties.$hour = (date) => {
			return i18nService.formatHour(date);
		};

		app.config.globalProperties.$time = (date) => {
			return i18nService.formatTime(date);
		};
	},
};

export default localizationPlugin;
