export function connatixSetup(adtype, connatixId) {
	if (
		adtype === 'connatix-elements' &&
		!document.getElementById('AWConnectConnatixElements')
	) {
		if (!window.cnx?.cmd) window.cnx = { cmd: [] };
		const container = document.createElement('iframe');
		container.src = 'javascript:false';
		container.id = 'AWConnectConnatixElements';
		container.style.display = 'none';
		container.onload = () => {
			const script = container.contentWindow.document.createElement('script');
			script.src =
				'https://cd.connatix.com/connatix.player.js?cid=' + connatixId;
			script.setAttribute('async', '1');
			script.setAttribute('type', 'text/javascript');
			container.contentWindow.document.body.appendChild(script);
		};
		document.body.appendChild(container);
	} else if (
		adtype === 'connatix-playspace' &&
		!document.getElementById('AWConnectConnatixPlayspace')
	) {
		if (!window.cnxps?.cmd) window.cnxps = { cmd: [] };
		const container = document.createElement('iframe');
		container.src = 'javascript:false';
		container.style.display = 'none';
		container.id = 'AWConnectConnatixPlayspace';
		container.onload = () => {
			const script = container.contentWindow.document.createElement('script');
			script.src =
				'https://cd.connatix.com/connatix.playspace.js?cid=' + connatixId;
			script.setAttribute('async', '1');
			script.setAttribute('type', 'text/javascript');
			container.contentWindow.document.body.appendChild(script);
		};
		document.body.appendChild(container);
	}
}
