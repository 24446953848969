import { weatherStore } from '@/stores/weather';

const availableWidgets = [
	'HourlyForecastWidget',
	'MinuteCastWidget',
	'AirQualityWidget',
	'RealFeelWidget',
	'LifeStyleWidget',
];

export function getStandardWidgets(slim) {
	const store = weatherStore();
	let standardCards = ['HourlyForecastWidget'];
	if (slim) {
		standardCards.unshift('CurrentConditionsWidget');
	}
	if (
		store.minutecastData?.Intervals?.slice(0, 60)
			.map((d) => d.Dbz)
			.reduce((total, current) => {
				return total + current;
			}, 0) !== 0
	) {
		standardCards.push('MinuteCastWidget');
	}
	if (store.airQualityData.overallPlumeLabsIndex > 51) {
		standardCards.push('AirQualityWidget');
	}
	if (
		store.currentConditionsData?.RealFeelTemperature.Imperial.Value > 101 ||
		store.currentConditionsData?.RealFeelTemperature.Imperial.Value < 9
	) {
		standardCards.push('RealFeelWidget');
	}
	if (store.activeIndexTypes.length > 0) {
		standardCards.push('LifeStyleWidget');
	}
	return standardCards;
}

export function getRoundRobinWidgets(slim) {
	let roundRobinWidgets = [...availableWidgets];
	if (slim) {
		roundRobinWidgets.unshift('CurrentConditionsWidget');
	}
	return roundRobinWidgets;
}

export function getSpecificWidget(widgetName, slim) {
	let specificWidgets = [...availableWidgets];
	specificWidgets.push('MapWidget');
	if (slim) {
		specificWidgets.push('CurrentConditionsWidget');
	}
	return (
		specificWidgets.find((widget) =>
			widget.toLowerCase().includes(widgetName.toLowerCase())
		) ?? getRoundRobinWidgets()
	);
}

export function getRandomWidget(slim) {
	let randomWidgets = [...availableWidgets];
	if (slim) {
		randomWidgets.push('CurrentConditionsWidget');
	}
	return randomWidgets[Math.floor(Math.random() * randomWidgets.length)];
}
