export function handleError(message, source, lineno, colno, error) {
	console.error(
		'error occurred outside vue instance',
		message,
		source,
		lineno,
		colno,
		error
	);
	return false;
}

export function handlePromiseRejection(event) {
	console.warn('UNHANDLED PROMISE REJECTION:', event.reason);
	event.preventDefault();
}

export function handleVueError(err, vm, info) {
	let componentName = vm.$options.name || '';
	console.error(info, 'error.', err);
	if (componentName.endsWith('Widget')) {
		if (componentName === 'BaseWidget') {
			componentName = vm.widget;
		}
		//forcing re-mounting of components to avoid inconsistencies with reactive properties while deleting the broken component after the current tick
		vm.$root.isLoading = true;
		vm.$nextTick(() => {
			const eligibleWidgets = vm.$root.widgetStore.eligibleWidgets;

			const index = eligibleWidgets.findIndex(
				(widget) => widget === componentName
			);
			if (index > -1) {
				console.warn(`removing ${componentName} due to an error`);

				//revert to the last valid widget on storage to prevent being stuck on index 0 all the time
				let storageKey = '';
				switch (this.layout) {
					case 'vertical':
						storageKey = 'currentWidget';
						break;
					case 'horizontal':
						storageKey = 'currentHorizontalWidget';
						break;
					case 'responsive':
						storageKey = 'currentResponsiveWidget';
						break;
				}
				const lastIndex = index > 0 ? index - 1 : eligibleWidgets.length - 1;
				localStorage[storageKey] = eligibleWidgets[lastIndex];

				eligibleWidgets.splice(index, 1);
				vm.$root.isLoading = false;
			}
		});
	}

	return false;
}

export function registerErrorHandler(VueApp) {
	window.onerror = handleError;
	window.onunhandledrejection = handlePromiseRejection;
	VueApp.errorCaptured = handleVueError;
}
