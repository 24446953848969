<template>
	<div :class="[{ [$style.slim]: slim }, $style['air-quality-content']]">
		<div :class="$style['aqi-graph-container']">
			<svg :width="graphWidth" :height="graphWidth">
				<g
					:class="[$style.particles, activeClass(groupIndex)]"
					v-for="(group, groupIndex) in particleGroups"
					:key="groupIndex"
				>
					<circle
						v-for="particle in groupSize"
						:key="particle"
						:cx="particlePosition(particle, groupIndex)"
						:cy="particlePosition(particle, groupIndex, true)"
						:r="particleRadius"
					/>
				</g>
			</svg>
			<div :class="$style['aqi-value-container']">
				<span>
					{{ aqi }}
				</span>
				<span :class="$style['aqi-label']">AQI</span>
			</div>
		</div>
		<div :class="$style['air-quality-summary']">
			<div :class="$style['aqi-category-color']" />
			<span :class="$style['aqi-category']">{{ category }}</span>
			<span :class="$style['aqi-hazard-statement']">{{ hazardStatement }}</span>
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia';
import { weatherStore } from '@/stores/weather';
export default {
	name: 'AirQualityWidget',
	props: ['slim'],
	data() {
		return {
			label: 'More Details',
			graphWidth: 82,
			centerOffset: 24,
			particleGroups: 32,
			groupSize: 3,
			particleRadius: 1,
			particleSpacing: 5,
			maxAQI: 250,
			customTitle: 'Air Quality',
		};
	},
	computed: {
		...mapState(weatherStore, ['airQualityData']),
		url() {
			return this.airQualityData.link;
		},
		categoryColor() {
			return this.airQualityData?.categoryColor ?? '#fff';
		},
		category() {
			return this.airQualityData?.category ?? '';
		},
		aqi() {
			return Math.round(this.airQualityData?.overallPlumeLabsIndex ?? 0);
		},
		particlePosition() {
			return (particle, groupIndex, isY = false) => {
				let center = this.graphWidth / 2;
				let arcModifier = Math.sin(this.radian(groupIndex));

				if (isY) {
					arcModifier = -1 * Math.cos(this.radian(groupIndex));
				}

				return (
					center +
					(this.centerOffset + particle * this.particleSpacing) * arcModifier
				);
			};
		},
		hazardStatement() {
			const statement = this.airQualityData?.hazardStatement ?? '';
			return statement.substring(0, statement.indexOf('.') + 1);
		},
	},
	methods: {
		activeClass(particleGroupIndex) {
			if ((this.aqi * this.particleGroups) / this.maxAQI > particleGroupIndex) {
				return this.$style.active;
			} else {
				return '';
			}
		},
		radian(protoAngle) {
			return (2 * Math.PI * protoAngle) / this.particleGroups;
		},
	},
};
</script>
<style lang="scss" scoped module>
.air-quality-content {
	display: flex;
	font-weight: 500;
	margin-top: 9px;
	&.slim {
		margin-top: 0px;
	}
	.aqi-graph-container {
		width: v-bind(graphWidth) px;
		height: v-bind(graphWidth) px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.particles {
		fill: #fff;
		&:not(.active) {
			opacity: 0.2;
		}
	}
	.aqi-value-container {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		font-size: 22px;
		font-weight: 500;
		margin-top: -11px;
		.aqi-label {
			position: absolute;
			top: 100%;
			width: 100%;
			opacity: 0.5;
			font-size: 10px;
		}
	}
	.air-quality-summary {
		display: flex;
		flex-direction: column;
		margin: 0 auto auto 24px;
		font-size: 10px;
		p {
			height: 20px;
			line-height: 1.96;
		}
		.aqi-category-color {
			width: 32px;
			height: 3px;
			margin-top: 4px;
			background-color: v-bind(categoryColor);
		}
		.aqi-category {
			margin: 8px 0;
			font-size: 14px;
			font-weight: 700;
		}
		.aqi-hazard-statement {
			font-size: 11px;
			line-height: 14px;
			height: 42px;
			overflow: hidden;
		}
	}
}
</style>
